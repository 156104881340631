// import React from 'react';
// import Leaf from '../assets/leaf.png';

// const SubscriptionSection: React.FC = () => {
//     return (
//       <section id="subscription"  className="relative py-12 mb-8">
//         <div className="container mx-auto text-center relative z-10">
//           {/* Subscribe Text */}
//           <p className="flex justify-center text-gray-900 mb-4">Subscribe to our newsletter to get updates early!</p>
          
//           {/* Form */}
//           <form className="relative flex justify-center items-center">
//             <input
//               type="email"
//               className="border border-gray-300 rounded-l-lg p-2 w-64 focus:outline-none"
//               placeholder="Your email address"
//             />
//             <button type="submit" className="bg-green-500 text-white py-2 px-6 rounded-r-lg">
//               Subscribe
//             </button>
//           </form>
//         </div>

//         {/* Leaf Image Behind the Form */}
//         <div className="absolute inset-0 flex justify-center items-center -z-10 ml-48">
//           <img src={Leaf} alt="leaf" className="h-44 ml-44" />
//         </div>
//       </section>
//     );
// };

// export default SubscriptionSection;

import React from 'react';
import Leaf from '../assets/leaf.png';

const SubscriptionSection: React.FC = () => {
    return (
      <section id="subscription" className="relative py-12 mb-8">
        <div className="container mx-auto text-center relative z-10">
          {/* Subscribe Text */}
          <p className="flex justify-center text-gray-900 mb-4 text-lg sm:text-base">
            Subscribe to our newsletter to get updates early!
          </p>
          
          {/* Form */}
          <form className="relative flex justify-center items-center w-full max-w-lg mx-auto px-4 sm:px-0">
            <input
              type="email"
              className="border border-gray-300 rounded-l-lg p-2 w-full sm:w-2/3 focus:outline-none"
              placeholder="Your email address"
            />
            <button type="submit" className="bg-green-500 text-white py-2 px-6 rounded-r-lg">
              Subscribe
            </button>
          </form>
        </div>

        {/* Leaf Image Behind the Form */}
        <div className="absolute inset-0 flex justify-center items-center -z-10">
          <img src={Leaf} alt="leaf" className="h-36 md:h-52 lg:h-44 object-cover transform translate-x-20 translate-y-1 sm:translate-x-36 md:translate-x-48" />
        </div>
      </section>
    );
};

export default SubscriptionSection;
