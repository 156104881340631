// import React, { useState } from "react";
// import Modal from "react-modal";
// import { IoArrowForwardCircleOutline, IoCloseOutline } from "react-icons/io5";
// import Oil from "../assets/oil.png"; // Ensure correct path
// import OrganicPeelPowder from "../assets/orange peel powder.jpg"; // Ensure correct path
// import CalamineClayPowder from "../assets/Calamine clay powder.jpg"; // Ensure correct path

// Modal.setAppElement("#root");

// const FeaturedProducts: React.FC = () => {
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState<{
//     image: string;
//     description: string;
//   } | null>(null);

//   const openModal = (image: string, description: string) => {
//     setSelectedProduct({ image, description });
//     setModalIsOpen(true);
//   };

//   const closeModal = () => {
//     setModalIsOpen(false);
//   };

//   return (
//     <div className="bg-white py-16 px-8">
//       <div className="max-w-6xl mx-auto">
//         <div className="text-center mb-10">
//           <h2 className="flex justify-start text-green-700 text-xl font-semibold">
//             Products
//           </h2>
//           <div className="flex flex-md:col">
//             <div className="flex flex-md:row">
//               <div className="flex flex-col">
//                 <div className="flex justify-start text-7xl font-bold text-gray-800 mt-2">
//                   Featured{" "}
//                 </div>
//                 <div className="text-7xl font-bold text-gray-900 mt-2">
//                   Products
//                 </div>
//               </div>
//             </div>
//             <p className="text-gray-500 mt-6 max-w-lg mx-auto text-left mr-10">
//               Our featured collection showcases the best of our skincare
//               expertise, designed to address a variety of skin concerns and
//               deliver exceptional results. Explore these natural and organic
//               products like Orange Peel Powder, Ubtan powder, & Neem Powder etc.
//               Each is crafted to elevate your skincare routine and reveal your
//               skin's natural beauty.
//             </p>
//           </div>
//           <div className="border-b-2 border-green-500 mt-7"></div>
//         </div>

//         <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-16 mt-20">
//           {/* Serums Card */}
//           <div className="bg-white shadow-lg rounded-lg overflow-hidden">
//             <div className="flex items-center justify-between  px-4">
//               <img
//                 src={OrganicPeelPowder}
//                 alt="Serum"
//                 className="h-86"
//                 onClick={() =>
//                   openModal(
//                     OrganicPeelPowder,
//                     "Welcome to the world of vitamin skincare, where we believe that beauty starts with healthy, radiant skin. Our mission is simple yet transformative: to unlock your skin's natural beauty potential."
//                   )
//                 }
//               />
//             </div>
//             <div className="p-6">
//               <div className="flex flex-md:row justify-between">
//                 <h3 className="text-green-700 text-xl font-semibold">
//                   Organic Peel Powder
//                 </h3>
//                 <div>
//                   <IoArrowForwardCircleOutline
//                     className="text-4xl cursor-pointer text-gray-500"
//                     onClick={() =>
//                       openModal(
//                         OrganicPeelPowder,
//                         "Welcome to the world of vitamin skincare, where we believe that beauty starts with healthy, radiant skin. Our mission is simple yet transformative: to unlock your skin's natural beauty potential."
//                       )
//                     }
//                   />
//                 </div>
//               </div>
//               <p className="text-gray-600 mt-4 text-left">
//                 Welcome to the world of SSFit skincare, where we believe that
//                 beauty starts with healthy, radiant skin. Our mission is simple
//                 yet transformative: to unlock your skin's natural beauty
//                 potential.
//               </p>
//             </div>
//           </div>

//           {/* Oils Card */}
//           <div className="mt-15">
//             <div className="bg-white shadow-lg rounded-lg overflow-hidden">
//               <div className="flex items-center justify-between  px-4">
//                 <img
//                   src={CalamineClayPowder}
//                   alt="Calamine Clay Powder"
//                   className="h-96"
//                   onClick={() =>
//                     openModal(
//                       CalamineClayPowder,
//                       "Most oils have emollient and occlusive properties, so they're able to support the skin barrier and lock in moisture. Oils are naturally rich in antioxidants and have anti-inflammatory properties."
//                     )
//                   }
//                 />
//               </div>
//               <div className="p-6">
//                 <div className="flex flex-md:row justify-between">
//                   <h3 className="text-green-700 text-xl font-semibold">
//                     Calamine Clay Powder
//                   </h3>
//                   <div>
//                     <IoArrowForwardCircleOutline
//                       className="text-4xl cursor-pointer text-gray-500"
//                       onClick={() =>
//                         openModal(
//                           CalamineClayPowder,
//                           "Most oils have emollient and occlusive properties, so they're able to support the skin barrier and lock in moisture. Oils are naturally rich in antioxidants and have anti-inflammatory properties."
//                         )
//                       }
//                     />
//                   </div>
//                 </div>
//                 <p className="text-gray-600 mt-4 text-left">
//                   Most Calamine Clay Powders have emollient and occlusive
//                   properties, so they're able to support the skin barrier and
//                   lock in moisture. Calamine Clay Powder is naturally rich in
//                   antioxidants and has anti-inflammatory properties.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Modal Popup */}
//         {selectedProduct && (
//           <Modal
//             isOpen={modalIsOpen}
//             onRequestClose={closeModal}
//             contentLabel="Product Details"
//             className="relative bg-white rounded-md p-4 max-w-xl mx-auto mt-32"
//             overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" // Transparent background
//           >
//             <button
//               onClick={closeModal}
//               className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
//             >
//               <IoCloseOutline className="text-3xl" />
//             </button>
//             <img
//               src={selectedProduct.image}
//               alt="Product"
//               className="mx-auto mt-4 h-96"
//             />
//             <h2 className="text-md text-gray-500 mt-4">
//               {selectedProduct.description}
//             </h2>
//             {/* <button
//               onClick={closeModal}
//               className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 transition"
//             >
//               Close
//             </button> */}
//           </Modal>
//         )}
//       </div>
//     </div>
//   );
// };

// export default FeaturedProducts;


import React, { useState } from "react";
import Modal from "react-modal";
import { IoArrowForwardCircleOutline, IoCloseOutline } from "react-icons/io5";
import OrganicPeelPowder from "../assets/orange peel powder.jpg"; // Ensure correct path
import CalamineClayPowder from "../assets/Calamine clay powder.jpg"; // Ensure correct path

Modal.setAppElement("#root");

const FeaturedProducts: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<{
    image: string;
    description: string;
  } | null>(null);

  const openModal = (image: string, description: string) => {
    setSelectedProduct({ image, description });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div id="products" className="bg-white py-8 px-4 md:py-16 md:px-8">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-10">
          <h2 className="text-green-700 text-xl font-semibold text-left">
            Products
          </h2>
          <div className="flex flex-col md:flex-row items-start justify-between">
            <div>
              <div className="text-4xl md:text-7xl font-bold text-gray-800 mt-2 leading-tight">
                Featured
              </div>
              <div className="text-4xl md:text-7xl font-bold text-gray-900 mt-2 leading-tight">
                Products
              </div>
            </div>
            <p className="text-gray-500 mt-4 md:mt-0 max-w-lg text-left md:text-base text-sm">
              Our featured collection showcases the best of our skincare expertise, designed to address a variety of skin concerns and deliver exceptional results. Explore these natural and organic products like Orange Peel Powder, Ubtan powder, & Neem Powder etc. Each is crafted to elevate your skincare routine and reveal your skin's natural beauty.
            </p>
          </div>
          <div className="border-b-2 border-green-500 mt-5 md:mt-7"></div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 mb-12 md:mb-16 mt-10 md:mt-20">
          {/* Serums Card */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="flex justify-center md:justify-between px-4">
              <img
                src={OrganicPeelPowder}
                alt="Serum"
                className="cursor-pointer"
                onClick={() =>
                  openModal(
                    OrganicPeelPowder,
                    "Welcome to the world of vitamin skincare, where we believe that beauty starts with healthy, radiant skin. Our mission is simple yet transformative: to unlock your skin's natural beauty potential."
                  )
                }
              />
            </div>
            <div className="p-4 md:p-6">
              <div className="flex justify-between">
                <h3 className="text-green-700 text-lg md:text-xl font-semibold">
                  Organic Peel Powder
                </h3>
                <IoArrowForwardCircleOutline
                  className="text-3xl md:text-4xl cursor-pointer text-gray-500"
                  onClick={() =>
                    openModal(
                      OrganicPeelPowder,
                      "Welcome to the world of vitamin skincare, where we believe that beauty starts with healthy, radiant skin. Our mission is simple yet transformative: to unlock your skin's natural beauty potential."
                    )
                  }
                />
              </div>
              <p className="text-gray-600 mt-4 text-sm md:text-base text-left">
                Welcome to the world of SSFit skincare, where we believe that beauty starts with healthy, radiant skin. Our mission is simple yet transformative: to unlock your skin's natural beauty potential.
              </p>
            </div>
          </div>

          {/* Oils Card */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="flex justify-center md:justify-between px-4">
              <img
                src={CalamineClayPowder}
                alt="Calamine Clay Powder"
                className="cursor-pointer"
                onClick={() =>
                  openModal(
                    CalamineClayPowder,
                    "Most oils have emollient and occlusive properties, so they're able to support the skin barrier and lock in moisture. Oils are naturally rich in antioxidants and have anti-inflammatory properties."
                  )
                }
              />
            </div>
            <div className="p-4 md:p-6">
              <div className="flex justify-between">
                <h3 className="text-green-700 text-lg md:text-xl font-semibold">
                  Calamine Clay Powder
                </h3>
                <IoArrowForwardCircleOutline
                  className="text-3xl md:text-4xl cursor-pointer text-gray-500"
                  onClick={() =>
                    openModal(
                      CalamineClayPowder,
                      "Most oils have emollient and occlusive properties, so they're able to support the skin barrier and lock in moisture. Oils are naturally rich in antioxidants and have anti-inflammatory properties."
                    )
                  }
                />
              </div>
              <p className="text-gray-600 mt-4 text-sm md:text-base text-left">
                Most Calamine Clay Powders have emollient and occlusive properties, so they're able to support the skin barrier and lock in moisture. Calamine Clay Powder is naturally rich in antioxidants and has anti-inflammatory properties.
              </p>
            </div>
          </div>
        </div>

        {/* Modal Popup */}
        {selectedProduct && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Product Details"
            className="relative bg-white rounded-md p-4 max-w-md md:max-w-xl mx-auto mt-20 md:mt-32"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
          >
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <IoCloseOutline className="text-2xl md:text-3xl" />
            </button>
            <img
              src={selectedProduct.image}
              alt="Product"
              className="mx-auto mt-4 h-64 md:h-96"
            />
            <h2 className="text-sm md:text-md text-gray-500 mt-4">
              {selectedProduct.description}
            </h2>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default FeaturedProducts;
