import React from 'react';

const ContactPage: React.FC = () => {
  return (
    <section id="contact" className="relative bg-gray-200 py-8">
      <div className="container mx-auto px-2 sm:px-4 lg:px-10">
        <div className="p-6 md:p-8 bg-white rounded-lg shadow-lg">
          <h3 className="flex justify-center text-2xl md:text-3xl font-semibold text-gray-700 mb-6 text-center md:text-left">Contact Us</h3>
          
          {/* Flex Container for Contact Info */}
          <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-6">
            {/* Left Column for Founders */}
            <div className="flex flex-col flex-grow space-y-2">
              <div>
                <span className="block">Contact No.: <span className="font-semibold">7905239287</span></span>
              </div>
            </div>

            {/* Right Column for Email */}
            <div className="flex flex-col flex-grow">
              <span className="font-bold">Email:</span>
              <span>
                <a href="mailto:ssfitorganic@gmail.com" className="text-green-600 hover:underline">
                  ssfitorganic@gmail.com
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
