// // import React, { useRef, useState } from "react";
// // import { Swiper as SwiperType } from "swiper/types";
// // import { Swiper, SwiperSlide } from "swiper/react";
// // import { Navigation, Pagination } from "swiper/modules";
// // import "swiper/css";
// // import "swiper/css/navigation";
// // import "swiper/css/pagination";
// // import KasturiHaldiPowder from "../assets/kasturi haldi powder.jpg";
// // import MultaniMittiPowder from "../assets/multani mitti powder.jpg";
// // import SandalwoodPowder from "../assets/Sandalwood powder.jpg";
// // import RosePetalsPowder from "../assets/Rose Petals Powder.jpg";
// // import OrangePeelPowder from "../assets/orange peel powder.jpg";
// // import UbtanPowder from "../assets/ubtan powder.jpg";
// // import CalamineClayPowder from "../assets/Calamine clay powder.jpg";
// // import KaolinClayPowder from "../assets/kaolin clay powder.jpg";
// // import NeemPowder from "../assets/neem powder.jpg";
// // import {
// //   IoArrowForwardCircleOutline,
// //   IoArrowBackCircleOutline,
// // } from "react-icons/io5";

// // const ExpertSkincare: React.FC = () => {
// //   const swiperRef = useRef<SwiperType | null>(null);
// //   const [activeArrow, setActiveArrow] = useState<"prev" | "next" | null>(null);
// //   const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state

// //   const handleNext = () => {
// //     if (swiperRef.current) {
// //       swiperRef.current.slideNext();
// //       setActiveArrow("next");
// //     }
// //   };

// //   const handlePrev = () => {
// //     if (swiperRef.current) {
// //       swiperRef.current.slidePrev();
// //       setActiveArrow("prev");
// //     }
// //   };

// //   const handleModalOpen = () => {
// //     setIsModalOpen(true);
// //   };

// //   const handleModalClose = () => {
// //     setIsModalOpen(false);
// //   };

// //   const images = [
// //     { src: RosePetalsPowder, label: "Rose Petals Powder" },
// //     { src: UbtanPowder, label: "Ubtan Powder" },
// //     { src: OrangePeelPowder, label: "Orange Peel Powder" },
// //     { src: SandalwoodPowder, label: "Sandalwood Powder" },
// //     { src: KaolinClayPowder, label: "Kaolin Clay Powder" },
// //     { src: CalamineClayPowder, label: "Calamine Clay Powder" },
// //     { src: MultaniMittiPowder, label: "Multani Mitti Powder" },
// //     { src: KasturiHaldiPowder, label: "Kasturi Haldi Powder" },
// //     { src: NeemPowder, label: "Neem Powder" },
// //   ];

// //   return (
// //     <section id="expert-skincare" className="bg-white py-16">
// //       <div className="container mx-auto">
// //         {/* Section Header */}
// //         <div className="text-center mb-8">
// //           <h3 className="flex justify-start text-green-500 text-xl font-bold">
// //             Products
// //           </h3>
// //           <h2 className="flex justify-start text-7xl font-bold mt-4 text-gray-700">
// //             Expert Skincare For{" "}
// //           </h2>
// //           <h2 className="flex justify-start text-7xl font-bold mt-2 text-gray-700">
// //             {" "}
// //             Your Beautiful Skin
// //           </h2>
// //           <div className="flex items-center justify-between space-x-4 mt-12">
// //             <p className="text-gray-600 text-left max-w-2xl">
// //               Experience radiant, healthy skin with our expert-crafted skincare
// //               range made from 100% organic ingredients...
// //             </p>
// //             <div className="flex justify-between">
// //               {/* Prev Arrow */}
// //               <button
// //                 onClick={handlePrev}
// //                 className={`text-5xl p-2 rounded-full transition-colors duration-300 ${
// //                   activeArrow === "prev"
// //                     ? "bg-green-600 text-white"
// //                     : "text-gray-600 hover:bg-green-600 hover:text-white"
// //                 }`}
// //               >
// //                 <IoArrowBackCircleOutline />
// //               </button>

// //               {/* Next Arrow */}
// //               <button
// //                 onClick={handleNext}
// //                 className={`text-5xl p-2 rounded-full ml-4 transition-colors duration-300 ${
// //                   activeArrow === "next"
// //                     ? "bg-green-600 text-white"
// //                     : "text-gray-600 hover:bg-green-600 hover:text-white"
// //                 }`}
// //               >
// //                 <IoArrowForwardCircleOutline />
// //               </button>
// //             </div>
// //           </div>
// //           <div className="border-b-2 border-green-500 mt-8"></div>
// //         </div>

// //         {/* Swiper Carousel */}
// //         <Swiper
// //           onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
// //           spaceBetween={20}
// //           slidesPerView={4}
// //           slidesPerGroup={4}
// //           pagination={{
// //             clickable: true,
// //             el: ".swiper-pagination",
// //             bulletClass: "swiper-pagination-bullet",
// //             bulletActiveClass: "swiper-pagination-bullet-active",
// //           }}
// //           modules={[Navigation, Pagination]}
// //           breakpoints={{
// //             640: {
// //               slidesPerView: 1,
// //               slidesPerGroup: 1,
// //             },
// //             768: {
// //               slidesPerView: 2,
// //               slidesPerGroup: 2,
// //             },
// //             1024: {
// //               slidesPerView: 4,
// //               slidesPerGroup: 4,
// //             },
// //           }}
// //         >
// //           {images.map((image, index) => (
// //             <SwiperSlide key={index}>
// //               <div className="p-6 border rounded-lg flex flex-col items-center">
// //                 <img src={image.src} alt={image.label} className="rounded-lg" />
// //               </div>
// //               <p className="text-green-500 font-bold mt-4">{image.label}</p>
// //             </SwiperSlide>
// //           ))}
// //         </Swiper>

// //         {/* Pagination Dots */}
// //         {/* <div className="flex justify-center space-x-2 mt-8">
// //           <div className="swiper-pagination"></div>
// //         </div> */}
// //         <div className="mt-4 text-right">
// //           <button
// //             className="text-green-500 text-lg hover:underline"
// //             onClick={handleModalOpen}
// //           >
// //             See all
// //           </button>
// //         </div>
// //         <div className="border-b-2 border-green-500 mt-14"></div>

// //         {/* Modal */}
// //         {isModalOpen && (
// //           <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
// //             <div className="bg-white p-8 rounded-lg max-w-3xl w-full relative max-h-svh overflow-y-auto">
// //               <button
// //                 className="absolute top-2 right-2 text-gray-500 hover:text-red-500 text-3xl"
// //                 onClick={handleModalClose}
// //               >
// //                 &times; {/* Close Icon */}
// //               </button>
// //               <h3 className="text-xl font-bold text-center mb-4">
// //                 All Products
// //               </h3>
// //               <div className="grid grid-cols-3 gap-4">
// //                 {" "}
// //                 {/* Change to 3 columns */}
// //                 {images.map((image, index) => (
// //                   <div
// //                     key={index}
// //                     className="p-4 border rounded-lg flex flex-col items-center"
// //                   >
// //                     <img
// //                       src={image.src}
// //                       alt={image.label}
// //                       className="rounded-lg mb-2"
// //                     />
// //                     <p className="text-green-500 font-bold">{image.label}</p>
// //                   </div>
// //                 ))}
// //               </div>
// //             </div>
// //           </div>
// //         )}
// //       </div>
// //     </section>
// //   );
// // };

// // export default ExpertSkincare;

// // import React, { useRef, useState } from "react";
// // import { Swiper as SwiperType } from "swiper/types";
// // import { Swiper, SwiperSlide } from "swiper/react";
// // import { Navigation, Pagination } from "swiper/modules";
// // import "swiper/css";
// // import "swiper/css/navigation";
// // import "swiper/css/pagination";
// // import KasturiHaldiPowder from "../assets/kasturi haldi powder.jpg";
// // import MultaniMittiPowder from "../assets/multani mitti powder.jpg";
// // import SandalwoodPowder from "../assets/Sandalwood powder.jpg";
// // import RosePetalsPowder from "../assets/Rose Petals Powder.jpg";
// // import OrangePeelPowder from "../assets/orange peel powder.jpg";
// // import UbtanPowder from "../assets/ubtan powder.jpg";
// // import CalamineClayPowder from "../assets/Calamine clay powder.jpg";
// // import KaolinClayPowder from "../assets/kaolin clay powder.jpg";
// // import NeemPowder from "../assets/neem powder.jpg";
// // import {
// //   IoArrowForwardCircleOutline,
// //   IoArrowBackCircleOutline,
// // } from "react-icons/io5";

// // const ExpertSkincare: React.FC = () => {
// //   const swiperRef = useRef<SwiperType | null>(null);
// //   const [activeArrow, setActiveArrow] = useState<"prev" | "next" | null>(null);
// //   const [isModalOpen, setIsModalOpen] = useState(false);

// //   const handleNext = () => {
// //     if (swiperRef.current) {
// //       swiperRef.current.slideNext();
// //       setActiveArrow("next");
// //     }
// //   };

// //   const handlePrev = () => {
// //     if (swiperRef.current) {
// //       swiperRef.current.slidePrev();
// //       setActiveArrow("prev");
// //     }
// //   };

// //   const handleModalOpen = () => {
// //     setIsModalOpen(true);
// //   };

// //   const handleModalClose = () => {
// //     setIsModalOpen(false);
// //   };

// //   const images = [
// //     { src: RosePetalsPowder, label: "Rose Petals Powder" },
// //     { src: UbtanPowder, label: "Ubtan Powder" },
// //     { src: OrangePeelPowder, label: "Orange Peel Powder" },
// //     { src: SandalwoodPowder, label: "Sandalwood Powder" },
// //     { src: KaolinClayPowder, label: "Kaolin Clay Powder" },
// //     { src: CalamineClayPowder, label: "Calamine Clay Powder" },
// //     { src: MultaniMittiPowder, label: "Multani Mitti Powder" },
// //     { src: KasturiHaldiPowder, label: "Kasturi Haldi Powder" },
// //     { src: NeemPowder, label: "Neem Powder" },
// //   ];

// //   return (
// //     <section id="expert-skincare" className="bg-white py-16">
// //       <div className="container mx-auto px-4">
// //         {/* Section Header */}
// //         <div className="text-center mb-8">
// //           <h3 className="text-green-500 text-xl font-bold">Products</h3>
// //           <h2 className="text-3xl md:text-6xl font-bold mt-4 text-gray-700">
// //             Expert Skincare For
// //           </h2>
// //           <h2 className="text-3xl md:text-6xl font-bold mt-2 text-gray-700">
// //             Your Beautiful Skin
// //           </h2>

// //           <div className="mt-6 flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0 md:space-x-4">
// //             <p className="text-gray-600 text-sm md:text-lg max-w-2xl text-left">
// //               Experience radiant, healthy skin with our expert-crafted skincare
// //               range made from 100% organic ingredients...
// //             </p>
// //             <div className="flex space-x-4">
// //               <button
// //                 onClick={handlePrev}
// //                 className={`text-4xl p-2 rounded-full transition-colors duration-300 ${
// //                   activeArrow === "prev"
// //                     ? "bg-green-600 text-white"
// //                     : "text-gray-600 hover:bg-green-600 hover:text-white"
// //                 }`}
// //               >
// //                 <IoArrowBackCircleOutline />
// //               </button>
// //               <button
// //                 onClick={handleNext}
// //                 className={`text-4xl p-2 rounded-full transition-colors duration-300 ${
// //                   activeArrow === "next"
// //                     ? "bg-green-600 text-white"
// //                     : "text-gray-600 hover:bg-green-600 hover:text-white"
// //                 }`}
// //               >
// //                 <IoArrowForwardCircleOutline />
// //               </button>
// //             </div>
// //           </div>
// //           <div className="border-b-2 border-green-500 mt-8"></div>
// //         </div>

// //         {/* Swiper Carousel */}
// //         <Swiper
// //           onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
// //           spaceBetween={20}
// //           slidesPerView={1}
// //           slidesPerGroup={1}
// //           pagination={{
// //             clickable: true,
// //           }}
// //           modules={[Navigation, Pagination]}
// //           breakpoints={{
// //             640: {
// //               slidesPerView: 1,
// //               slidesPerGroup: 1,
// //             },
// //             768: {
// //               slidesPerView: 2,
// //               slidesPerGroup: 2,
// //             },
// //             1024: {
// //               slidesPerView: 4,
// //               slidesPerGroup: 4,
// //             },
// //           }}
// //         >
// //           {images.map((image, index) => (
// //             <SwiperSlide key={index}>
// //               <div className="p-6 border rounded-lg flex flex-col items-center">
// //                 <img
// //                   src={image.src}
// //                   alt={image.label}
// //                   className="rounded-lg max-h-56 object-cover"
// //                 />
// //               </div>
// //               <p className="text-green-500 font-bold mt-4">{image.label}</p>
// //             </SwiperSlide>
// //           ))}
// //         </Swiper>

// //         {/* Pagination */}
// //         <div className="mt-4 text-right ">
// //           <button
// //             className="text-green-500 text-lg hover:underline "
// //             onClick={handleModalOpen}
// //           >
// //             See all
// //           </button>
// //         </div>
// //         <div className="border-b-2 border-green-500 mt-14"></div>

// //         {/* Modal */}
// //         {isModalOpen && (
// //           <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
// //             <div className="bg-white p-8 rounded-lg max-w-3xl w-full relative max-h-screen overflow-y-auto">
// //               <button
// //                 className="absolute top-2 right-2 text-gray-500 hover:text-red-500 text-3xl"
// //                 onClick={handleModalClose}
// //               >
// //                 &times;
// //               </button>
// //               <h3 className="text-xl font-bold text-center mb-4">
// //                 All Products
// //               </h3>
// //               <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
// //                 {images.map((image, index) => (
// //                   <div
// //                     key={index}
// //                     className="p-4 border rounded-lg flex flex-col items-center"
// //                   >
// //                     <img
// //                       src={image.src}
// //                       alt={image.label}
// //                       className="rounded-lg mb-2 max-h-32 object-cover"
// //                     />
// //                     <p className="text-green-500 font-bold text-sm text-center">
// //                       {image.label}
// //                     </p>
// //                   </div>
// //                 ))}
// //               </div>
// //             </div>
// //           </div>
// //         )}
// //       </div>
// //     </section>
// //   );
// // };

// // export default ExpertSkincare;

// import React, { useRef, useState } from "react";
// import { Swiper as SwiperType } from "swiper/types";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import KasturiHaldiPowder from "../assets/kasturi haldi powder.jpg";
// import MultaniMittiPowder from "../assets/multani mitti powder.jpg";
// import SandalwoodPowder from "../assets/Sandalwood powder.jpg";
// import RosePetalsPowder from "../assets/Rose Petals Powder.jpg";
// import OrangePeelPowder from "../assets/orange peel powder.jpg";
// import UbtanPowder from "../assets/ubtan powder.jpg";
// import CalamineClayPowder from "../assets/Calamine clay powder.jpg";
// import KaolinClayPowder from "../assets/kaolin clay powder.jpg";
// import NeemPowder from "../assets/neem powder.jpg";
// import {
//   IoArrowForwardCircleOutline,
//   IoArrowBackCircleOutline,
// } from "react-icons/io5";

// const ExpertSkincare: React.FC = () => {
//   const swiperRef = useRef<SwiperType | null>(null);
//   const [activeArrow, setActiveArrow] = useState<"prev" | "next" | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const handleNext = () => {
//     if (swiperRef.current) {
//       swiperRef.current.slideNext();
//       setActiveArrow("next");
//     }
//   };

//   const handlePrev = () => {
//     if (swiperRef.current) {
//       swiperRef.current.slidePrev();
//       setActiveArrow("prev");
//     }
//   };

//   const handleModalOpen = () => {
//     setIsModalOpen(true);
//   };

//   const handleModalClose = () => {
//     setIsModalOpen(false);
//   };

//   const images = [
//     { src: RosePetalsPowder, label: "Rose Petals Powder" },
//     { src: UbtanPowder, label: "Ubtan Powder" },
//     { src: OrangePeelPowder, label: "Orange Peel Powder" },
//     { src: SandalwoodPowder, label: "Sandalwood Powder" },
//     { src: KaolinClayPowder, label: "Kaolin Clay Powder" },
//     { src: CalamineClayPowder, label: "Calamine Clay Powder" },
//     { src: MultaniMittiPowder, label: "Multani Mitti Powder" },
//     { src: KasturiHaldiPowder, label: "Kasturi Haldi Powder" },
//     { src: NeemPowder, label: "Neem Powder" },
//   ];

//   return (
//     <section id="expert-skincare" className="bg-white py-8">
//       <div className="container mx-auto px-4">
//         {/* Section Header */}
//         <div className="text-center mb-8">
//           <h3 className="flex justify-start text-green-500 text-xl font-bold">Products</h3>
//           <h2 className=" flex justify-start text-3xl md:text-6xl font-bold mt-4 text-gray-700">
//             Expert Skincare For
//           </h2>
//           <h2 className="flex justify-start text-3xl md:text-6xl font-bold mt-2 text-gray-700">
//             Your Beautiful Skin
//           </h2>

//           <div className="mt-6 flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0 md:space-x-4">
//             <p className="text-gray-600 text-sm md:text-lg max-w-2xl text-left">
//               Experience radiant, healthy skin with our expert-crafted skincare
//               range made from 100% organic ingredients...
//             </p>
//             <div className="flex space-x-4">
//               <button
//                 onClick={handlePrev}
//                 className={`text-4xl p-2 rounded-full transition-colors duration-300 ${
//                   activeArrow === "prev"
//                     ? "bg-green-600 text-white"
//                     : "text-gray-600 hover:bg-green-600 hover:text-white"
//                 }`}
//               >
//                 <IoArrowBackCircleOutline />
//               </button>
//               <button
//                 onClick={handleNext}
//                 className={`text-4xl p-2 rounded-full transition-colors duration-300 ${
//                   activeArrow === "next"
//                     ? "bg-green-600 text-white"
//                     : "text-gray-600 hover:bg-green-600 hover:text-white"
//                 }`}
//               >
//                 <IoArrowForwardCircleOutline />
//               </button>
//             </div>
//           </div>
//           <div className="border-b-2 border-green-500 mt-8"></div>
//         </div>

//         {/* Swiper Carousel */}
//         <Swiper
//           onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
//           spaceBetween={20}
//           slidesPerView={1}
//           slidesPerGroup={1}
//           pagination={{
//             clickable: true,
//             el: ".swiper-pagination", // Specify a custom element for pagination
//             bulletClass: "swiper-pagination-bullet",
//             bulletActiveClass: "swiper-pagination-bullet-active",
//           }}
//           modules={[Navigation, Pagination]}
//           breakpoints={{
//             640: {
//               slidesPerView: 1,
//               slidesPerGroup: 1,
//             },
//             768: {
//               slidesPerView: 2,
//               slidesPerGroup: 2,
//             },
//             1024: {
//               slidesPerView: 4,
//               slidesPerGroup: 4,
//             },
//           }}
//         >
//           {images.map((image, index) => (
//             <SwiperSlide key={index}>
//               <div className="p-6 border rounded-lg flex flex-col items-center">
//                 <img
//                   src={image.src}
//                   alt={image.label}
//                   className="rounded-lg max-h-56 object-cover"
//                 />
//               </div>
//               <p className="text-green-500 font-bold mt-4">{image.label}</p>
//             </SwiperSlide>
//           ))}
//         </Swiper>

//         {/* Centered Pagination Below Text
//         <div className="swiper-pagination mt-8 flex justify-center"></div> */}

//         {/* See All Button */}
//         <div className="mt-4 text-right">
//           <button
//             className="text-green-500 text-lg hover:underline"
//             onClick={handleModalOpen}
//           >
//             See all
//           </button>
//         </div>
//         <div className="border-b-2 border-green-500 mt-14"></div>

//         {/* Modal */}
//         {isModalOpen && (
//           <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//             <div className="bg-white p-8 rounded-lg max-w-3xl w-full relative max-h-screen overflow-y-auto">
//               <button
//                 className="absolute top-2 right-2 text-gray-500 hover:text-red-500 text-3xl"
//                 onClick={handleModalClose}
//               >
//                 &times;
//               </button>
//               <h3 className="text-xl font-bold text-center mb-4">
//                 All Products
//               </h3>
//               <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
//                 {images.map((image, index) => (
//                   <div
//                     key={index}
//                     className="p-4 border rounded-lg flex flex-col items-center"
//                   >
//                     <img
//                       src={image.src}
//                       alt={image.label}
//                       className="rounded-lg mb-2 max-h-32 object-cover"
//                     />
//                     <p className="text-green-500 font-bold text-sm text-center">
//                       {image.label}
//                     </p>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     </section>
//   );
// };

// export default ExpertSkincare;

import React, { useRef, useState } from "react";
import { Swiper as SwiperType } from "swiper/types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import KasturiHaldiPowder from "../assets/kasturi haldi powder.jpg";
import MultaniMittiPowder from "../assets/multani mitti powder.jpg";
import SandalwoodPowder from "../assets/Sandalwood powder.jpg";
import RosePetalsPowder from "../assets/Rose Petals Powder.jpg";
import OrangePeelPowder from "../assets/orange peel powder.jpg";
import UbtanPowder from "../assets/ubtan powder.jpg";
import CalamineClayPowder from "../assets/Calamine clay powder.jpg";
import KaolinClayPowder from "../assets/kaolin clay powder.jpg";
import NeemPowder from "../assets/neem powder.jpg";
import {
  IoArrowForwardCircleOutline,
  IoArrowBackCircleOutline,
} from "react-icons/io5";

const ExpertSkincare: React.FC = () => {
  const swiperRef = useRef<SwiperType | null>(null);
  const [activeArrow, setActiveArrow] = useState<"prev" | "next" | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
      setActiveArrow("next");
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
      setActiveArrow("prev");
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const images = [
    { src: RosePetalsPowder, label: "Rose Petals Powder" },
    { src: UbtanPowder, label: "Ubtan Powder" },
    { src: OrangePeelPowder, label: "Orange Peel Powder" },
    { src: SandalwoodPowder, label: "Sandalwood Powder" },
    { src: KaolinClayPowder, label: "Kaolin Clay Powder" },
    { src: CalamineClayPowder, label: "Calamine Clay Powder" },
    { src: MultaniMittiPowder, label: "Multani Mitti Powder" },
    { src: KasturiHaldiPowder, label: "Kasturi Haldi Powder" },
    { src: NeemPowder, label: "Neem Powder" },
  ];

  return (
    <section id="expert-skincare" className="bg-white py-8">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8">
          <h3 className="flex justify-start text-green-500 text-xl font-bold">
            Products
          </h3>
          <h2 className=" flex justify-start text-3xl md:text-6xl font-bold mt-4 text-gray-700">
            Expert Skincare For
          </h2>
          <h2 className="flex justify-start text-3xl md:text-6xl font-bold mt-2 text-gray-700">
            Your Beautiful Skin
          </h2>

          <div className="mt-6 flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0 md:space-x-4">
            <p className="text-gray-600 text-sm md:text-lg max-w-2xl text-left">
              Experience radiant, healthy skin with our expert-crafted skincare
              range made from 100% organic ingredients like Kasturi Haldi,
              Sandalwood, Rose Petals, and Orange Peel powders. Infused with the
              natural detoxifying properties of Kaolin Clay, our Ubtan powder
              gently exfoliates, brightens, and rejuvenates your skin. Embrace
              the power of nature for a glowing complexion—because your skin
              deserves the finest, chemical-free care. Perfect for all skin
              types!
            </p>
            <div className="flex space-x-4">
              <button
                onClick={handlePrev}
                className={`p-2 text-6xl rounded-full transition-colors duration-300 ${
                  activeArrow === "prev"
                    ? "bg-green-600 text-white"
                    : "text-gray-600 hover:bg-green-600 hover:text-white"
                }`}
              >
                <IoArrowBackCircleOutline />
              </button>
              <button
                onClick={handleNext}
                className={`p-2 text-6xl rounded-full transition-colors duration-300 ${
                  activeArrow === "next"
                    ? "bg-green-600 text-white"
                    : "text-gray-600 hover:bg-green-600 hover:text-white "
                }`}
              >
                <IoArrowForwardCircleOutline />
              </button>
            </div>
          </div>
          <div className="border-b-2 border-green-500 mt-8"></div>
        </div>

        {/* Swiper Carousel */}
        <Swiper
          onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
          spaceBetween={20}
          slidesPerView={1}
          slidesPerGroup={1}
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
            bulletClass: "swiper-pagination-bullet",
            bulletActiveClass: "swiper-pagination-bullet-active",
          }}
          modules={[Navigation, Pagination]}
          breakpoints={{
            640: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            1024: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
          }}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="p-6 border rounded-lg flex flex-col items-center">
                <img
                  src={image.src}
                  alt={image.label}
                  className="rounded-lg max-h-56 object-cover"
                />
              </div>
              <p className="text-green-500 font-bold mt-4">{image.label}</p>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="mt-4 text-right">
          <button
            className="text-green-500 text-lg hover:underline"
            onClick={handleModalOpen}
          >
            See all
          </button>
        </div>
        <div className="border-b-2 border-green-500 mt-14"></div>

        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded-lg max-w-3xl w-full relative max-h-screen overflow-y-auto">
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-red-500 text-3xl"
                onClick={handleModalClose}
              >
                &times;
              </button>
              <h3 className="text-xl font-bold text-center mb-4">
                All Products
              </h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                {images.map((image, index) => (
                  <div
                    key={index}
                    className="p-4 border rounded-lg flex flex-col items-center"
                  >
                    <img
                      src={image.src}
                      alt={image.label}
                      className="rounded-lg mb-2 max-h-32 object-cover"
                    />
                    <p className="text-green-500 font-bold text-sm text-center">
                      {image.label}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ExpertSkincare;
