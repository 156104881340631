// import React, { useState } from "react";
// import { FaSearch, FaShoppingCart, FaUserAlt } from "react-icons/fa";
// import Serum from "../assets/serum.png";
// import Background from "../assets/background.png";
// const Header: React.FC = () => {
//   const [activeTab, setActiveTab] = useState("Products");

//   const tabs = ["Products", "About", "Tips", "Contact"];

//   return (
//     <header
//       className="relative bg-green-100 shadow-sm"
//       style={{
//         backgroundImage: `url(${require("../assets/image.png")})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         backgroundRepeat: "no-repeat",
//         height: "100vh",
//       }}
//     >
//       <div className="relative z-10 container mx-auto flex items-center justify-between p-6">
//         {/* Brand Name */}
//         <div className="text-2xl font-bold text-black">SS FIT ORGANIC</div>

//         {/* Centered Navigation Tabs */}
//         <nav className="flex-grow flex justify-center space-x-6">
//           {tabs.map((tab) => (
//             <a
//               href={`#${tab.toLowerCase()}`}
//               key={tab}
//               className={`text-lg font-semibold ${
//                 activeTab === tab ? "text-green-600" : "text-gray-400"
//               }`}
//               onClick={() => setActiveTab(tab)}
//             >
//               {tab}
//             </a>
//           ))}
//         </nav>

//         {/* Icons on the Right */}
//         {/* <div className="flex space-x-6 text-gray-400">
//           <FaSearch className="cursor-pointer" />
//           <FaShoppingCart className="cursor-pointer" />
//           <FaUserAlt className="cursor-pointer" />
//         </div> */}
//       </div>

//       {/* Hero Section */}

//       <div className="flex flex-md:row">
//         <div className="flex flex-md:column">
//           <div className="relative z-10 text-center py-16">
//             <h1 className="text-4xl  text-gray-500">
//               <span className="flex justify-left text-2xl font-bold text-green-500 ml-36 mt-20">
//                 {" "}
//                 Unlock Your Skin's Natural{" "}
//               </span>{" "}
//               <span
//                 className="flex justify-left text-8xl ml-36 text-gray-600 fontFamily-Playfair-display"
//                 style={{ fontFamily: "Playfair Display", fontWeight: "900" }}
//               >
//                 Beauty
//               </span>
//             </h1>
//             <p className="flex justify-start ml-36 text-gray-500 mt-4 max-w-xs mx-auto text-left">
//               Discover SSfit Organic, where nature meets skincare. Our herbal
//               formulas nourish and rejuvenate, unlocking your skin's natural
//               beauty. Embrace nature’s power for a radiant, vibrant complexion.
//               Transform your routine today!
//             </p>

//             {/* Action Buttons */}
//             <div className="flex justify-start ml-36 mt-8 space-x-4">
//               {/* <button className="bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700">
//                 Shop Now
//               </button> */}
//               <button className="border border-green-600 b-2 text-green-600 py-2 px-4 rounded-lg hover:bg-green-300">
//                 Learn More
//               </button>
//             </div>
//           </div>
//           <div className="flex justify-center items-center space-x-4 mt-8 mb-10">
//             <img src={Serum} alt="Description of image" className="ml-10" />{" "}
//             {/* Adjust size as needed */}
//             <img
//               src={Background}
//               alt="A serene natural landscape"
//               className="mt-44"
//             />
//           </div>
//           {/* <div className="max-w-xs mx-auto p-6 ">

//          <h2 className="text-2xl font-semibold text-black text-center">What we offer</h2>
//          <p className="text-center text-gray-600">Our serums offer you not just skincare, but an experience.</p>
//          <button className="mt-4 px-6 py-2  text-gray-600 rounded w-full">Explore More</button>
//        </div> */}
//         </div>
//       </div>
//     </header>
//   );
// };

// export default Header;

import React, { useState } from "react";
import { FaSearch, FaShoppingCart, FaUserAlt } from "react-icons/fa";
import Serum from "../assets/serum.png";
import Background from "../assets/background.png";

const Header: React.FC = () => {
  const [activeTab, setActiveTab] = useState("Products");

  const tabs = ["Products", "About", "Tips", "Contact"];

  return (
    <header
      className="relative bg-green-100 shadow-sm "
      style={{
        backgroundImage: `url(${require("../assets/backgground.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <div className="relative z-10 container mx-auto flex items-center justify-between p-4 md:p-6">
        {/* Brand Name */}
        <div className="text-2xl font-bold text-black">SS FIT ORGANIC</div>

        {/* Centered Navigation Tabs */}
        <nav className="hidden md:flex flex-grow justify-center space-x-6">
          {tabs.map((tab) => (
            <a
              href={`#${tab.toLowerCase()}`}
              key={tab}
              className={`text-lg font-semibold ${
                activeTab === tab ? "text-green-600" : "text-gray-400"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </a>
          ))}
        </nav>

        {/* Icons on the Right (hidden on mobile) */}
        {/* <div className="hidden md:flex space-x-6 text-gray-400">
          <FaSearch className="cursor-pointer" />
          <FaShoppingCart className="cursor-pointer" />
          <FaUserAlt className="cursor-pointer" />
        </div> */}
      </div>

      {/* Hero Section */}
      <div className="flex flex-col md:flex-row relative">
        <div className="flex flex-col items-center md:items-start relative z-10">
          <h1 className="text-3xl md:text-4xl text-gray-500 text-center md:text-left">
            <span className="flex justify-start text-xl font-bold text-green-500 mt-24 md:ml-36">
              Unlock Your Skin's Natural
            </span>
            <span
              className="flex justify-start text-5xl md:text-8xl text-gray-600 fontFamily-Playfair-display md:ml-36"
              style={{ fontFamily: "Playfair Display", fontWeight: "900" }}
            >
              Beauty
            </span>
          </h1>
          <p className="flex justify-start md:mx-0 text-gray-900 mt-4 max-w-xs mx-auto text-left md:ml-36">
            Discover SSfit Organic, where nature meets skincare. Our herbal
            formulas nourish and rejuvenate, unlocking your skin's natural
            beauty. Embrace nature’s power for a radiant, vibrant complexion.
            Transform your routine today!
          </p>

          {/* Action Buttons */}
          <div className="flex justify-center mt-4 space-x-4">
            {/* <button className="bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700">
              Shop Now
            </button> */}
            <button className="border border-green-600 text-green-600 py-2 px-4 rounded-lg hover:bg-green-300 md:ml-36" >
              Learn More
            </button>
          </div>
        </div>

        {/* Images Section */}
        <div className="flex flex-row items-center w-full mt-4 md:mt-12 md:ml-60 ">
          {/* First Image in front of the text */}
          <div className="relative mb-4 flex justify-center">
            <img
              src={Serum}
              alt="Serum Image"
              className="h-auto w-full md:w-full" // Adjust size as needed
            />
          </div>

          {/* Second Image Centered */}
          <div className="relative flex justify-end mt-[-80px] md:mt-52 md:ml-50">
            <img
              src={Background}
              alt="Background Image"
              className="h-auto w-full md:w-full" // Adjust size as needed
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
