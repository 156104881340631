// import React from "react";

// const SkincareTips: React.FC = () => {
//   return (
//     <section id="tips" className="py-16 bg-gray-50">
//       <div className="container mx-auto px-6">
//         <h2 className="flex justify-start text-2xl font-bold text-green-600 mb-4">
//           Tips
//         </h2>
//         <div className="flex flex-md:col space-x-16 justify-between">
//           <div className="flex flex-md:row">
//             <div className="flex flex-col">
//               <div className="text-6xl font-extrabold  text-gray-800">
//                 Skincare Tips
//               </div>
//               <div className="mb-4 text-6xl font-extrabold text-gray-800 mt-2">
//                 And Insights
//               </div>
//             </div>
//           </div>
//           <p className=" text-gray-600 mb-12 max-w-2xl text-left">
//             Skincare Tips and Insights section. We believe that achieving
//             beautiful, radiant skin goes beyond using our products—it’s about
//             understanding your skin’s unique needs and nurturing it with care.
//             Here, we share expert advice, insights, and tips to help you on your
//             journey to healthier, glowing skin.
//           </p>
//         </div>
//         <div className="border-b-2 border-green-500 mb-10 rounded-lg "></div>
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
//           {/* Tip 1 */}
//           <div>
//             <h4 className="flex justify-start text-2xl font-bold mb-4">
//               1. The Power of Herbal & Organic Powder
//             </h4>
//             <p className="text-gray-600 mb-6 text-left max-w-md mt-8">
//               Herbal and organic powders are skincare superheroes, delivering
//               concentrated nutrients to your skin. Lightweight and versatile,
//               they can be mixed into masks or scrubs to target hydration,
//               anti-aging, and brightening. With ingredients like Kasturi,Haldi
//               and Sandalwood, they nourish and rejuvenate for a radiant
//               complexion.
//               <div className="border-b-2 border-green-500 mt-5 "></div>
//             </p>
//             <img
//               src={
//                 "https://thesophisticatedshediary.com/wp-content/uploads/2024/01/woman-1867891_640.jpg"
//               }
//               alt="Face Serums"
//               className="rounded-lg shadow-md object-cover h-100 w-70"
//             />
//           </div>
//           {/* Tip 2 */}
//           <div>
//             <img
//               src={
//                 "https://anydayguide.com/uploads/articles/beauty-style/beauty/diy-hair-rinses.jpg"
//               }
//               alt="Skincare"
//               className="rounded-lg shadow-md object-cover mb-6 "
//               style={{ height: "70%" }}
//             />
//             <h4 className="flex justify-start text-2xl font-bold mb-4 mt-16 ">
//               2. The Importance of a Skincare Routine
//             </h4>
//             <p className="text-gray-600 text-left max-w-md mt-10 ml-6">
//               Consistency is crucial for healthy skin. A daily routine of
//               cleansing, toning, herbal powder treatments, and moisturizing
//               significantly enhances your skin’s health and appearance, ensuring
//               a radiant, youthful glow for years to come. Embrace the power of
//               daily care!
//               <div className="border-b-2 border-green-500 mt-7 "></div>
//             </p>
//           </div>
//         </div>

//         {/* See More Button */}
//         {/* <div className="mt-10 text-center">
//           <button className="border border-green-500 px-6 py-2 rounded-lg text-green-500 hover:bg-green-500 hover:text-white ">
//             See more
//           </button>
//         </div> */}
//       </div>
//     </section>
//   );
// };

// export default SkincareTips;

import React from "react";

const SkincareTips: React.FC = () => {
  return (
    <section id="tips" className="py-16 bg-gray-50">
      <div className="container mx-auto px-6">
        <h2 className="text-2xl font-bold text-green-600 mb-4 text-left">Tips</h2>
        <div className="flex flex-col lg:flex-row lg:space-x-16 space-y-6 lg:space-y-0 justify-start">
          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-col">
              <div className="flex justify-start text-4xl sm:text-3xl md:text-5xl lg:text-6xl font-extrabold text-gray-800">
                Skincare Tips
              </div>
              <div className="flex justify-start mb-4 text-4xl sm:text-3xl md:text-5xl lg:text-6xl font-extrabold text-gray-800 mt-2">
                And Insights
              </div>
            </div>
          </div>
          <p className="text-gray-600 mb-12 max-w-2xl text-left text-base lg:text-lg">
            Skincare Tips and Insights section. We believe that achieving
            beautiful, radiant skin goes beyond using our products—it’s about
            understanding your skin’s unique needs and nurturing it with care.
            Here, we share expert advice, insights, and tips to help you on your
            journey to healthier, glowing skin.
          </p>
        </div>
        <div className="border-b-2 border-green-500 mb-10 rounded-lg mt-10"></div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Tip 1 */}
          <div>
            <h4 className="text-2xl font-bold mb-4 text-left">1. The Power of Herbal & Organic Powder</h4>
            <p className="text-gray-600 mb-6 text-left max-w-md mt-4">
              Herbal and organic powders are skincare superheroes, delivering
              concentrated nutrients to your skin. Lightweight and versatile,
              they can be mixed into masks or scrubs to target hydration,
              anti-aging, and brightening. With ingredients like Kasturi, Haldi
              and Sandalwood, they nourish and rejuvenate for a radiant
              complexion.
              <div className="border-b-2 border-green-500 mt-5 mb-4"></div>
            </p>
            
            <img
              src={
                "https://thesophisticatedshediary.com/wp-content/uploads/2024/01/woman-1867891_640.jpg"
              }
              alt="Face Serums"
              className="rounded-lg shadow-md object-cover w-full h-auto max-w-lg" // Made image smaller and responsive
            />
          </div>
          {/* Tip 2 */}
          <div>
            <img
              src={
                "https://anydayguide.com/uploads/articles/beauty-style/beauty/diy-hair-rinses.jpg"
              }
              alt="Skincare"
              className="rounded-lg shadow-md object-cover mb-6 w-full h-auto max-w-lg mx-auto lg:mx-0" // Adjusted image size for Tip 2
            />
            <h4 className="text-2xl font-bold mb-4 mt-8 text-left">2. The Importance of a Skincare Routine</h4>
            <p className="text-gray-600 text-left max-w-md mt-4">
              Consistency is crucial for healthy skin. A daily routine of
              cleansing, toning, herbal powder treatments, and moisturizing
              significantly enhances your skin’s health and appearance, ensuring
              a radiant, youthful glow for years to come. Embrace the power of
              daily care!
              <div className="border-b-2 border-green-500 mt-5 mb-4"></div>
            </p>
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default SkincareTips;


{
  /* <div className="flex flex-col items-end overflow-hidden h-screen"> */
}
{
  /* Repeat the div for multiple slanting lines */
}
{
  /* {[...Array(20)].map((_, index) => (
        <div
          key={index}
          className="w-full border-b border-gray-500"
          style={{
            transform: 'rotate(-30deg)',
            transformOrigin: 'bottom right',
            height: '2px',
            marginBottom: '-12px', // This ensures there's no gap between the lines
          }}
        ></div>
      ))} */
}
{
  /* Horizontal base line */
}
{
  /* <div className="w-full border-t border-gray-500"></div>
    </div> */
}
{
  /* <div className="border-r-2 border-green-500 h-30" > */
}

{
  /* </div> */
}
