import React from 'react';
import Header from '../components/header';
// import BrandsSection from '../components/brand';
import FeaturedProducts from '../components/product';
import AboutUs from '../components/aboutUs';
import SkincareTips from '../components/skinCareTips';
import ExpertSkincare from '../components/expertSkincare';
import SubscriptionSection from '../components/subscription';
import Footer from '../components/footer';
import ContactPage from '../components/contact';

const HomePage: React.FC = () => {
  return (
    <div>
      <Header  />
      {/* <BrandsSection /> */}
      <FeaturedProducts />
      <AboutUs />
      <SkincareTips />
      <ExpertSkincare />
      <SubscriptionSection />
      <ContactPage />
      <Footer />
    </div>
  );
};

export default HomePage;
