import React from "react";
import BlurImg from "../assets/blurImg.jpg";
import MultiLeaf from "../assets/multiLeaf.png";
import DownLeaf from "../assets/downLeaf.png";
import Cosmetics from '../assets/cosmectics.png';
import KitImg from "../assets/kitImg.jpg";

const AboutUs: React.FC = () => {
  return (
    <section id="about" className="relative bg-green-100 py-16">
      {/* Leaf decoration at the top right */}
      <img
        src={MultiLeaf}
        alt="leaf decoration"
        className="absolute top-0 right-0 h-52 w-52"
      />
      {/* Container for text and content */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-16">
        {/* Title Section */}
        <div className="mb-12">
          <h3 className="flex justify-start text-green-600 text-xl font-bold mb-2">About Us</h3>
          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col">
              <h1 className="flex justify-start text-4xl md:text-6xl font-bold text-gray-800 leading-tight">
                About 
              </h1>
              <h1 className="flex justify-start text-4xl md:text-6xl font-bold text-gray-800 leading-tight">
               Our Brand
              </h1>
            </div>
          </div>
          <p className="text-gray-600 text-lg mt-8">
            Welcome to the world of SSFit Organic Solutions, we bring the
            essence of nature to your skincare, offering a premium range of
            organic and herbal products. Our carefully curated collection,
            featuring Kasturi Haldi, Sandalwood, Rose Petal, Orange Peel
            powders, and Ubtan enriched with Kaolin Clay, is designed to
            rejuvenate, brighten, and nourish your skin. We are committed to
            purity, using only the finest, chemical-free ingredients to deliver
            visible results without compromise. With a focus on sustainability
            and wellness, SSFit empowers you to embrace natural beauty with
            products that honor both your skin and the environment. Experience
            the power of nature, beautifully crafted for you.
          </p>
          <hr className="border-t-2 border-green-500 mt-16 mx-auto" />
        </div>

        {/* Image and content grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-20">
          {/* Left Image & Text */}
          <div className="flex flex-col items-center">
            <img
              src={BlurImg}
              alt="Skincare Products"
              className="mb-4 h-60 w-60 object-cover rounded-tl-xl rounded-bl-xl items-center"
            />
            <p className="text-gray-700 mt-5 mb-14 text-center max-w-xs">
              Our journey began with a deep passion for skincare and the belief
              that everyone deserves access to high-quality, transformative
              products.
            </p>
          </div>

          {/* Center Image & Text */}
          <div className="flex flex-col items-center">
            <img
              src={Cosmetics}
              alt="Makeup Brushes"
              className="rounded-tl-xl rounded-bl-xl mb-4 h-96 w-full object-cover"
            />
            <p className="text-gray-700 text-center">
              At SSFit, our commitment is to your skin’s health and confidence.
              We understand that skincare is personal, and we take that
              responsibility seriously.
            </p>
          </div>

          {/* Right Image & Text */}
          <div className="flex flex-col items-center">
            <img
              src={KitImg}
              alt="Skincare Products"
              className="rounded-tl-xl rounded-bl-xl mb-4 h-full w-full object-cover"
            />
            <p className="text-gray-700 text-center">
              Our Herbal products harness the power of science and nature to
              deliver transformative results. We carefully select potent,
              natural ingredients and pair them with cutting-edge technology to
              create formulas that nourish, hydrate, and rejuvenate your skin.
            </p>
          </div>
        </div>
      </div>

      {/* Leaf decoration at the bottom left */}
      <img
        src={DownLeaf}
        alt="leaf decoration"
        className="absolute bottom-0 left-0 h-52 w-52"
      />
    </section>
  );
};

export default AboutUs;
